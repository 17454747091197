import React from "react"
import { graphql } from "gatsby"
import { Content } from "react-bulma-components"

import Layout from "../components/Layout"
import Gallery from "../components/PhotoswipeGallery"


export default ({ data }) => { 
    const { title, subtitle } = data.markdownRemark.frontmatter
    const { html } = data.markdownRemark
    const { slug } = data.markdownRemark.fields
    const featuredImage = 
        data.markdownRemark.frontmatter.featuredImage &&
        data.markdownRemark.frontmatter.featuredImage.childImageSharp.fluid
    const galleryImages =
        data.markdownRemark.frontmatter.gallery &&
        data.markdownRemark.frontmatter.gallery
    return (
        <Layout 
            title={title} 
            subtitle={subtitle}
            image={galleryImages ? null : featuredImage}
            slug={slug}
        >
            <Content>
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </Content>
            {galleryImages &&
                <Gallery images={galleryImages} />
            }
        </Layout>
    )
}


export const query = graphql`
    query($slug: String!) {
        markdownRemark(
            fields: { 
                slug: { eq: $slug }
            }
        ) {
            html
            frontmatter {
                title
                subtitle
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 290) {
                            ...GatsbyImageSharpFluid
                            presentationWidth
                        }
                    }
                }
                gallery {
                    src {
                        childImageSharp {
                            fluid(maxWidth: 400) {
                                ...GatsbyImageSharpFluid
                                presentationWidth
                                presentationHeight
                            }
                            original {
                                src, width, height
                            }
                        }
                    }
                    text
                }
            }
            fields {
                slug
            }
        }
    }
`