import "react-photoswipe/lib/photoswipe.css"

import React from "react"
import Img from "gatsby-image"
import { Card } from "react-bulma-components"
import { PhotoSwipeGallery } from "react-photoswipe"


const getThumbnailContent = (item) =>
        <Card>
            <div className="card-image">
                <Img
                    fluid={item.fluid}
                    title={item.title}
                    alt={item.title}
                />
            </div>
            <Card.Content>
                {item.title}
            </Card.Content>
        </Card>

const Gallery = ({images}) => {
    const items = images.map( i => ({
       src: i.src.childImageSharp.original.src,
       w: i.src.childImageSharp.original.width,
       h: i.src.childImageSharp.original.height,
       title: i.text,
       fluid: i.src.childImageSharp.fluid
    }))
    return (
        <PhotoSwipeGallery 
            items={items} 
            options={{
                shareEl: false,
                fullscreenEl: false, 
            }}
            thumbnailContent={getThumbnailContent} 
        />
    )
}

export default Gallery